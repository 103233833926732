import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>Automatisert testing</h4>
    <Link to="/news/automatisert_testing" className="inline-block max-w-sm bg-gray-100 px-8 py-6 shadow border border-gray-500 rounded" mdxType="Link">
  <Image className="mt-0" src="/news/test-suites-passed.png" mdxType="Image" />
  <p className="text-black mt-6 text-lg">
    (In norwegian) Hva er det som egentlig er det viktigste med automatisert
    testing av programvare?
  </p>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      